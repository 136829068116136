import styled, { css, device } from "@styled";

export const CaseStudyWrap = styled.section`
  padding: 40px 0;
  ${device.small} {
    padding: 60px 0;
  }
  ${device.medium} {
    padding: 80px;
  }
  ${device.large} {
    padding: 140px 0;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background-image: linear-gradient(-33deg, #15054f 0%, #561783 99%, #561783 100%);
      .section-title{
      h2{
      color:#fff
      }
      }
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: #f8f8f8;
    `}
    ${(props) =>
    props.layout === 3 &&
    css`
      ${device.large} {
        padding: 100px 0 10px;
      }
        background: #f8f8f8;
      `}
    .reviewsScriptWrapper {
      height: 450px;
      background: #eee;
      border-radius: 6px;
      margin-top: 20px;
      border: 1px solid #ddd;
      margin-bottom: 120px;
      box-shadow: 0 0 15px -5px rgb(0 0 0 / 20%);
      ${device.medium} {
        padding-bottom: 80px;
      }
      ${device.large} {
        padding-bottom: 0;
      }
      &-inner {
        height: 100%;
        ${device.medium} {
          padding-top: 24px;
        }
      }
    }
`;

// export const BlogRow = styled.div`
//   display:flex;
//   flex-wrap:wrap;
//   grid-gap:20px 15px;
//   margin-top:25px;
//   ${device.medium}{
//       margin-top:0;
//   }
//   ${device.xlarge}{
//     grid-gap:25px;
//   }
// `
// export const ImageWrap = styled.div`
//   border-radius: 6px;
//   overflow: hidden;
//   position:relative;
//   opacity:0.5;
//   &::after{
//     content:'';
//     position:absolute;
//     top:0;
//     left:0;
//     width:100%;
//     height:100%;
//     background-image: linear-gradient(0deg, rgba(0,0,0,0.9999999999999999) 0%, rgba(0,0,0,0) 100%);
//   }
//   .gatsby-image-wrapper {
//     width: 100%;
//   }
//   img {
//     width: 100%;
//     height: 250px;
//     object-fit: cover;
//     transition:all .7s ease-in-out;
//     ${device.large}{
//       height: 225px;
//     }
//     ${device.xlarge}{
//       height: 275px;
//     }
//   }
// `;
// export const BlogTagsWrap = styled.div`
//       position:absolute;
//       top:10px;
//       right:10px;
//       border-radius: 8px;
//       background-color:rgba(0, 0, 0, 0.502);
//       font-size: 14px;
//       letter-spacing: 0px;
//       line-height: 1;
//       color: #ffffff;
//       padding: 10px 15px;
//       ${device.xlarge}{
//         font-size: 15px;
//         padding: 13px 20px;
//       }
// `
// export const CaseStudyTextWrap = styled.div`
//   position:absolute;
//   bottom:15px;
//   left:0;
//   z-index:1;
//   padding: 0 15px;
//   ${device.xlarge} {
//     padding: 0 20px;
//   }
//   h4 {
//     font-size: 18px;
//     letter-spacing: 1px;
//     line-height: 1.2;
//     color: #ffffff;
//     font-weight: 600;
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     margin:0;
//     ${device.xlarge} {
//       font-size: 20px;
//     }
//   }
// `;
// export const BlogIconWrap = styled.div`
//   display:flex;
//   align-items:center;
//   flex-wrap:wrap;
//   grid-gap:15px;
//   margin-top:10px;
//   ${device.xlarge}{
//     grid-gap:40px;
//   }
// `
// export const BlogIconInner = styled.div`
//   display:flex;
//   align-items:center;
//   grid-gap:10px;
//   h6{
//     font-size: 14px;
//     letter-spacing: 0px;
//     line-height: 1.3;
//     color: #ffffff;
//     margin:0;
//     font-weight:400;
//     ${device.xlarge}{
//       font-size: 15px;
//     }
//   }
// `
// export const BlogColumn = styled.div`
//     width:100%;
//     position:relative;
//     cursor:pointer;
//     ${device.medium}{
//        width:calc(50% - 15px);
//     }
//     ${device.large}{
//       width:calc(33.3% - 25px);
//     }
//     ${device.xlarge}{
//       width:calc(33.3% - 25px);
//     }
//     &:hover{
//       .blogImgWrapper{
//         img{
//           transform:scale(1.05);
//         }
//       }
//     }
//     &:first-child, &:nth-child(2){
//       width:100%;
//       ${device.medium}{
//         width:calc(50% - 15px);
//      }
//       ${device.xlarge}{
//         width:calc(50% - 25px);
//       }
//       .blogImgWrapper{
//         img{
//           height: 250px;
//           ${device.xlarge}{
//             height:300px;
//           }
//           ${device.xlarge}{
//             height:408px;
//           }
//         }
//       }
//     }
// `
export const ButtonWrapTop = styled.div`
  margin-top: 30px;
  ${device.large} {
    text-align: right;
    margin-top: 0;
  }
  a {
    margin: 0;
  }
`;
// export const ButtonWrap = styled.div`
//   margin-top: 34px;
//   a {
//     border: none;
//     color: ${themeGet("colors.primary")};
//     font-size: 15px;
//     font-weight: bold;
//     padding: 0;
//     height: initial;
//     line-height: 1;
//     background: none;
//     &:hover {
//       color: ${themeGet("colors.primary")};
//     }
//   }
// `;
