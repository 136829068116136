import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import SectionTitle from '@ui/section-title'
// import Heading from '@ui/heading'
import Button from '@ui/button'
// import Image from '@ui/image'
// import axios from 'axios';
// import author from "@data/images/icons/author.png";
// import calendar from "@data/images/icons/calendar.png";
// import moment from 'moment'
// import { useBlogApi } from "@hooks";
import {
  CaseStudyWrap,
  // ImageWrap,
  // CaseStudyTextWrap,
  ButtonWrapTop,
  // BlogColumn,
  // BlogRow,
  // BlogIconInner,
  // BlogIconWrap,
  // BlogTagsWrap
} from './style'

const BlogSection = ({
  HeadingStyle,
  data,
  dataSinglePage,
  layout,
  ...props
}) => {
  // const [posts, setPosts] = useState([]);
  // const blogUrl = useBlogApi();
  // useEffect(() => {
  //   const fetchBlogs = async () => {
  //     try {
  //       const response = await axios.get('/api/blogApi');
  //       setPosts(response.data);
  //     } catch (error) {
  //       console.error('Error fetching blog posts:', error);
  //     }
  //   };

  //   fetchBlogs();
  // }, []);
  // useEffect(() => {
  //   const fetchBlogs = async () => {
  //     const token = "pat-na1-47ebcfd4-ceab-485a-b821-ce21a1b5f885";
  //    try {
  //     const response = await axios.get(
  //       blogUrl,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Access-Control-Allow-Origin": `${'http://localhost:8000/' ||  'https://gatsby.andovar.com/' || 'https://andovar.com/' }`,
  //           "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  //           "Access-Control-Allow-Headers":
  //             "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  //         },
  //       }
  //     );
  //     setPosts(response.data);
  //    } catch (error) {
  //     console.error('Error fetching blog posts:', error);
  //    }
  //   };

  //   fetchBlogs();
  // }, [blogUrl]);
  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <CaseStudyWrap layout={layout} {...props} id={data?.section}>
      <Container>
        <Row className='align-items-center'>
          <Col lg={8}>
            <SectionTitle
              title={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.title
                  : data.section_title?.title
              }
              subtitle={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.subtitle
                  : data.section_title?.subtitle
              }
              description={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.description
                  : data.section_title?.description
              }
            />
          </Col>
          <Col lg={4}>
            <ButtonWrapTop>
              {data?.buttons?.map(({ id, content,path, ...rest }) => (
                <Button onClick={() => handleButtonClick(path)} key={id} m='7px' {...rest}>
                  {content}
                </Button>
              ))}
            </ButtonWrapTop>
          </Col>
        </Row>

        {/* <BlogRow>
          {posts &&
            posts?.results?.map((el, index) => (
              <BlogColumn key={index} onClick={() => handleButtonClick(el.url)}>
                  <ImageWrap className="blogImgWrapper">
                    <Image
                      src={el.featuredImage}
                      alt={el.featuredImageAltText || 'Icon'}
                    />
                  </ImageWrap>
                   <BlogTagsWrap>{data?.tags[index].content}</BlogTagsWrap>
                  <CaseStudyTextWrap>
                    <Heading as='h4'>{el.name}</Heading>
                    <BlogIconWrap>
                      <BlogIconInner>
                        <Image
                          src={author}
                          alt={'Icon'}
                        />  
                        <Heading as='h6'>{el.authorName}</Heading>
                      </BlogIconInner>
                      <BlogIconInner>
                        <Image
                          src={calendar}
                          alt={'Icon'}
                        />  
                        <Heading as='h6'>{moment(el.publishDate).format('DD MMM YYYY')}</Heading>
                      </BlogIconInner>
                    </BlogIconWrap>
                  </CaseStudyTextWrap>
              </BlogColumn>
            ))}
        </BlogRow> */}
      </Container>
    </CaseStudyWrap>
  )
}

BlogSection.propTypes = {
  layout: PropTypes.oneOf([1, 2])
}

BlogSection.defaultProps = {
  layout: 1
}

export default BlogSection
