import React from "react";
import PropTypes from "prop-types";
import Heading from "@ui/heading";
import Image from "@ui/image";
import Button from '@ui/button'
import { HeadingType, TextType, ButtonType, ImageType } from "@utils/types";
import {
  HeroWrapper,
  HeroWrapperText,
  HeroWrapperTextTop,
  HerologosList,
  HerologosItem,
  StyledBG,
} from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { PageHeaderImgWrap } from "../../page-header/layout-01/style";
import GlobalRating from "@components/global-rating";
// import HeroQuote from "@components/hero-quote-form";
const HeroArea = ({ data }) => {
  // const tabActive = (id) => {
  //   if (typeof window !== "undefined" && !!document.getElementById(id)) {
  //     document.getElementById(id).scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //       inline: "nearest", 
  //     }); 
  //   }
  // };
  return (
    <HeroWrapper>
      <Container>
        <Row className="h-100 position-relative">
          <Col lg={6}>
            <HeroWrapperText>
                <HeroWrapperTextTop>
                <Heading as="h3">{data.texts[0]?.content}</Heading>
                <Heading
                  as="h1"
                  dangerouslySetInnerHTML={{ __html: data.texts[1]?.content }}
                ></Heading>
                <Heading>{data.texts[3]?.content}</Heading>
                {/* <HeroQuote /> */}
              </HeroWrapperTextTop>
              {data.buttons &&
                data?.buttons.slice(0, 1)?.map(({ id, content, ...rest }) => (
                  <Button key={id} m='7px' {...rest}>
                    {content}
                  </Button>
                ))}

              {data.buttons &&
                data?.buttons.slice(1, 2)?.map(({ id, content, ...rest }) => (
                  <Button
                    key={id}
                    // onClick={() => tabActive(path)}
                    m='7px'
                    className='scrollBtn'
                    {...rest}
                  >
                    {content}
                  </Button>
                ))}
              <GlobalRating pageHeader={data?.id}></GlobalRating>
              <HerologosList>
                {data &&
                  data.items[0].images.map((el, index) => (
                    <HerologosItem key={index} className="logo-box">
                      <Image src={el.src} alt={el?.alt || "Logo"} loading="eager" />
                    </HerologosItem>
                  ))}
              </HerologosList>
            </HeroWrapperText>
          </Col>
          {/* {data &&
            data.items[4].images.map((el, index) => (
              <HeroCenterIcon
                key={index}
                onClick={() => tabActive("translate-images-body")}
              >
                <Image src={el.src} alt={el?.alt || "Logo"} loading="eager" />
                <StaticImage
                      src='../../../data/images/hero/mouse-icon.png'
                      alt='mouse-icon' 
                      loading="eager"
                    />
              </HeroCenterIcon>
            ))} */}
          <Col lg={6}>
            <PageHeaderImgWrap>
              <StyledBG>
                {data &&
                  data.items[3].images.map((el, index) => (
                    <Image key={index} src={el.src} alt={el?.alt || "Logo"} loading="eager" />
                  ))}
              </StyledBG>
            </PageHeaderImgWrap>
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
};

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
  }),
};

export default HeroArea;
